<template>
  <div>
    <div class="container" v-if="!loading">
      <div class="row mb-3">
        <div class="col">
          <p class="alert alert-success">
            Treatment charges are added to the room bill and are to be paid on site at the end of your stay.
          </p>
        </div>
      </div>

      <div class="card border-0 shadow-none">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col my-auto">
              <h3 class="text-success fw-bold mb-0">
                Treatment Bookings
              </h3>
            </div>

            <div class="col-auto my-auto ms-auto">
              <router-link to="/treatments/create" class="btn btn-outline-success"
                ><i class="far fa-plus me-2"></i>Add New Booking</router-link
              >
            </div>
          </div>

          <table class="table" v-if="treatmentBookings.length > 0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Retreat</th>
                <th scope="col">Price</th>
                <th scope="col">Credits</th>
                <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in treatmentBookings" :key="t.id">
                <th @click="viewTreatment(t.id)" scope="row">
                  {{ t.id }}
                </th>
                <td @click="viewTreatment(t.id)" class="text-capitalize">
                  {{ t.booking.retreat == "malta" ? "Malta" : "UK" }}
                </td>
                <td @click="viewTreatment(t.id)">
                  <span v-if="t.type == 'standard'">
                    {{
                      t.booking.retreat == "malta"
                        ? "€" + t.total_euro.toFixed(2)
                        : "£" + t.total.toFixed(2)
                    }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td @click="viewTreatment(t.id)">
                  <span v-if="t.type == 'bundle'">
                    {{
                      t.credits
                    }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td @click="viewTreatment(t.id)">
                  {{ t.created_at | formatDate }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="my-5 text-center" v-else>
            <h4 class="text-success fw-bold">
              You don't have any treatment bookings yet, create one using the
              button above
            </h4>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      treatmentBookings: [],
      loading: false,
    };
  },
  methods: {
    viewTreatment(id) {
      this.$router.push("/treatments/" + id);
    },
    fetchTreatmentBookings() {
      this.loading = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/c/treatment-bookings")
        .then(({ data }) => {
          this.loading = false;
          this.treatmentBookings = data;
        });
    },
  },
  mounted() {
    this.fetchTreatmentBookings();
  },
  filters: {
    formatDate: function(date) {
      return moment(date).format("LLL");
    },
    formatStatus: function(status) {
      return status.replace("_", " ");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
